<template>
    <div class="orders-history-wrapper">
        <b-container fluid>
            <h1> {{ $t('headings.orders_history') }} </h1>
            <b-row class="orders-history-header">
                <b-col xl="6" md="6" sm="12">
                    <div class="oh-side oh-left">
                        <div class="input-group">
                            <input type="text" :placeholder="$t('placeholder.search_order')" id="history-search" name="" value="">
                            <div class="general-icon-button">
                                <i class="fas fa-search"></i>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="order-history-row">
                <b-col xl="9" lg="9" md="9">
                    <div class="orders-history-container">
                        <div class="orders-history-list" role="tablist">
                            <div class="oh-single-item" v-for="(order, key) in orders" :key="key">
                                <div class="oh-single-collapse" role="tab">
                                    <div class="main-id">
                                        <div class="status-i" :class="statusColors.find(value => parseInt(value.internalCode) === parseInt(order.status.internal_code)).label">
                                            <i class="fas fa-tag"></i>
                                        </div>
                                        <p class="order-id"> {{order.id}} </p>
                                    </div>
                                    <b-container>
                                        <b-row>
                                            <b-col xl="9" lg="9" md="9" sm="9">
                                                <div class="single-item-element">
                                                    <ul class="visible-info">
                                                        <li>
                                                            <h5>{{ $t('placeholder.address') }} </h5>
                                                            <p>
                                                                {{order.shippingAddress.phone}} | {{order.shippingAddress.address}} {{order.shippingAddress.details ? ',' + order.shippingAddress.details : ''}} , {{ $t('placeholder.area') }}( {{order.shippingAddress.city}} )
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <h5> {{ $t('placeholder.products') }} </h5>
                                                            <p v-html="order.productsSummary">
                                                            </p>
                                                        </li>
                                                        <li>
                                                            <h5> {{ $t('placeholder.total') }} </h5>
                                                            <p>
                                                                {{formatPrice(order.total)}} RON | {{order.paymentType}}
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </b-col>
                                            <b-col xl="3" lg="3" md="3" sm="3">
                                                <div class="single-item-element oh-cta-element">
                                                    <button class="negative-button" block @click="cancelOrder(order.id)" v-if="parseInt(order.status.internal_code) < 4">
                                                        <i class="fas fa-ban"></i>
                                                    </button>
                                                    <router-link :to="{ name: 'EditOrder', params: { id: order.id }}" class="oh-cta-button" v-if="parseInt(order.status.internal_code) < 3">
                                                        <i class="far fa-edit"></i>
                                                    </router-link>
                                                    <button class="oh-cta-button" block href="#" v-b-toggle="'singleOrder' + key">
                                                        <i class="fas fa-angle-double-down"></i>
                                                    </button>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </div>
                                <b-collapse class="oh-collapse-content" :id="'singleOrder' + key" accordion="order-accordion" role="tabpanel">
                                    <div class="oh-collapse-inner">
                                        <h5 class="oh-collapse-heading"> {{ $t('headings.additional_info') }} </h5>
                                        <ul class="oh-collapse-list">
                                            <li>
                                                <p> {{ $t('placeholder.client_name') }} </p>
                                                <p>
                                                    {{order.customer_name}}
                                                </p>
                                            </li>
                                            <li>
                                                <p> {{ $t('placeholder.phone') }} </p>
                                                <p>
                                                    {{order.shippingAddress.phone}}
                                                </p>
                                            </li>
                                            <li>
                                                <p> {{ $t('placeholder.order_mentions') }} </p>
                                                <p>
                                                    {{order.observations}}
                                                </p>
                                            </li>
                                            <li>
                                                <p> {{ $t('placeholder.delivery_mentions') }} </p>
                                                <p>
                                                    {{order.delivery_obs}}
                                                </p>
                                            </li>
                                        </ul>
                                        <h5> {{ $t('headings.products_summary') }} </h5>
                                        <ul class="oh-collapse-list">
                                            <li v-for="(product, key) in order.products" :key="key">
                                                <p> {{product.name}} </p>
                                                <span> {{product.qty}} X </span>
                                                <span> {{formatPrice(product.price)}} RON </span>
                                                <span>{{product.mentions ? " ( " + product.mentions + " )" : ''}}</span>
                                            </li>
                                        </ul>

                                        <div class="order-timeline-wrapper">
                                            <h5> {{ $t('headings.order_stage') }} </h5>
                                            <div class="timeline-bar-container">
                                                <div class="bar">
                                                    <div class="dot dot1">
                                                        <div class="message">
                                                            <p>
                                                                <i class="far fa-clock"></i>
                                                                {{order.created_at}}
                                                            </p>
                                                            <p> {{ $t('status.order_initated') }} </p>
                                                        </div>
                                                    </div>
                                                    <div class="dot dot2">
                                                        <div class="message">
                                                            <p>
                                                                <i class="far fa-clock"></i>
                                                                {{order.operator_delivery_time}}
                                                            </p>
                                                            <p> {{ $t('status.estimated_delivery_time') }} </p>
                                                        </div>
                                                    </div>
                                                    <div class="dot dot3">
                                                        <div class="message">
                                                            <p>
                                                                <i class="far fa-clock"></i>
                                                                {{order.delivered_start_at}}
                                                            </p>
                                                            <p> {{ $t('status.order_sent') }} </p>
                                                        </div>
                                                    </div>
                                                    <div class="dot dot4">
                                                        <div class="message">
                                                            <p>
                                                                <i class="far fa-clock"></i>
                                                                {{order.delivered_at}}
                                                            </p>
                                                            <p> {{ $t('status.order_delivered') }} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>
                        </div>
                    </div>
                    <div class="orders-view-more">
                        <button type="button" @click="viewMoreOrders()">
                            {{ $t('placeholder.see_more') }}
                        </button>
                    </div>
                </b-col>
                <b-col xl="3" md="3" sm="12">
                    <div class="legend-container">
                        <h4> {{ $t('headings.legend') }} </h4>
                        <ul class="legend-list">
                            <li>
                                <span class="dot initiated-bckg dot1"></span>
                                <span class="status-text"> {{ $t('status.order_initated') }} </span>
                            </li>
                            <li>
                                <span class="dot received-bckg dot2"></span>
                                <span class="status-text"> {{ $t('status.order_received') }} </span>
                            </li>
                            <li>
                                <span class="dot inprogress-bckg dot3"></span>
                                <span class="status-text"> {{ $t('status.order_inprogress') }} </span>
                            </li>
                            <li>
                                <span class="dot delivered-bckg dot4"></span>
                                <span class="status-text"> {{ $t('status.order_delivered') }} </span>
                            </li>
                            <li>
                                <span class="dot canceled-bckg dot5"></span>
                                <span class="status-text"> {{ $t('status.order_canceled') }} </span>
                            </li>
                        </ul>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import DeliveryOrders from '@/api-services/orders.service'
export default {
  name: 'OrdersList',
  data () {
    return {
      page: 1,
      orders: [],
      isFlipped: false,
      activeIndex: undefined,
      items: null,
      error: null,
      statusColors: [
        { label: 'initiated-color-i', internalCode: 1 },
        { label: 'received-color-i', internalCode: 2 },
        { label: 'in-progress-color-i', internalCode: 3 },
        { label: 'delivered-color-i', internalCode: 4 },
        { label: 'canceled-color-i', internalCode: 5 }
      ]
    }
  },
  components: {
  },
  methods: {
    setActive (index) {
      this.activeIndex = index
    },
    async getOrders (params = null) {
      await DeliveryOrders.getAll(params)
        .then((response) => {
          if (response.data.data.length > 0) {
            this.orders.push.apply(this.orders, response.data.data)
          } else {
            console.log('No orders')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    cancelOrder (orderId) {
      let vm = this
      this.$swal.fire({
        title: this.$t('alert.negative.cancel_order') + ' ' + '#' + orderId + ' ! ' + this.$t('alert.negative.certain_txt'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('alert.positive.confirm'),
        cancelButtonText: this.$t('alert.negative.cancel')
      }).then((result) => {
        if (result.value === true) {
          let data = {
            id: orderId
          }
          DeliveryOrders.cancelOrder(data)
            .then((response) => {
              if (response.data && response.data.status === 200) {
                let ord = vm.orders.find(o => parseInt(o.id) === parseInt(orderId))
                vm.orders[vm.orders.lastIndexOf(ord)].status = response.data.data.status
                console.log(vm.orders[vm.order.lastIndexOf(ord)])
              }
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
    viewMoreOrders () {
      this.page += 1
      this.getOrders({ page: this.page })
    }
  },
  mounted () {
  },
  async created () {
    await this.getOrders()
    this.$store.dispatch('hideLoading')
    window.Echo.channel('operator-order-status-update').listen('OrderStatusUpdate', (e) => {
      let o = this.orders.find(o => parseInt(o.id) === parseInt(e.data.id))
      if (o) {
        this.orders[this.orders.lastIndexOf(o)].status = e.data.status
      }
    })
  }
}
</script>
